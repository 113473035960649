import React from "react"
import "./sports.scss"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import { titleDesign } from "../../../utils"

import SliderNavigation from "../SharedComponents/SliderNavigation"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql,navigate } from "gatsby"
import { useContext,useState } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import ReactModal from 'react-modal'
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function SustainabilityReport(props) {
  const swiperRef = React.useRef(null)
  const { data } = props
  const [isOpenDownload, setOpenDownload] = useState(false)
  const {
    locale,
    originalUrl, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations,site } = useStaticQuery(graphql`
    query {
      stringTranslations {
        downlaod_ar: translateString(language: AR, string: "تحميل")
        downlaod_en: translateString(language: EN, string: "Download")
             fname_ar:translateString(language: AR, string: "FIRST NAME")
      fname_en:translateString(language: EN, string: "FIRST NAME")
      lname_ar:translateString(language: AR, string: "LAST NAME")
      lname_en:translateString(language: EN, string: "LAST NAME")
      email_ar:translateString(language: AR, string: "EMAIL")
      email_en:translateString(language: EN, string: "EMAIL")
      phone_ar:translateString(language: AR, string: "PHONE")
      phone_en:translateString(language: EN, string: "PHONE")
      msg_ar:translateString(language: AR, string: "صفة المستفيد")
      msg_en:translateString(language: EN, string: "ROLE TYPE")
      submit_ar:translateString(language: AR, string: "SUBMIT")
      submit_en:translateString(language: EN, string: "SUBMIT")


      selectowner_ar:translateString(language: AR, string: "مالك")
      selectowner_en:translateString(language: EN, string: "Owner")
      selectengoff_ar:translateString(language: AR, string: "مكتب هندسي / معماري")
      selectengoff_en:translateString(language: EN, string: "Engineering Office / Architect")
      selectpbuyer_ar:translateString(language: AR, string: "مشتري محتمل")
      selectpbuyer_en:translateString(language: EN, string: "Potential Buyer")
      selectagent_ar:translateString(language: AR, string: "وكالة / وكيل")
      selectagent_en:translateString(language: EN, string: "Agency / Agent ")
      selectother_ar:translateString(language: AR, string: "أخرى")
      selectother_en:translateString(language: EN, string: "Other")
      loading_ar:translateString(language: AR, string: "LOADING")
      loading_en:translateString(language: EN, string: "LOADING")
      ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
      ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
      ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
      ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
      }
       site {
      siteMetadata {
        formSubmissionUrl
      }
    }
    }
  `)
  const shouldRenderArrows = data?.length > 1
  const handleModalOpenDownload = event => {
   console.log("okkk");
    setFormState({
      ...formState,
      ['file']: event.currentTarget.dataset.file
    })
    setOpenDownload(true)
  }
 

  const handleModalClose = event => {
    setOpenDownload(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
  const [formState, setFormState] = useState({})
  const handleFormSubmit = (e) => {
    formSubmitHandler(e, formState)
  } 

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }
  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <p>{errors.errors}</p>
         
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  const formSubmitHandler = async (event, payload) => {
    event.preventDefault()
   

   
    const result = await executeRecaptcha('contact')
    var fieldname = "g-recaptcha-response";
    payload[fieldname] = result;
    payload['lang'] = locale;
    setLoading(true)
    setError(null)
    setValidationErrors(null)

    fetch('/reportdownload/report', {
      method: "POST",
      body: jsonToFormData(payload),
    })
    .then((resp) => resp.json())
      .then((resp) => resp)  .then((resp) => {
        if (resp.status !== "success") {
            setValidationErrors(resp.message)
            throw resp.message
        }else{   
        navigate("/"+locale+'/success-download?email='+payload.email)
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const [isSent, setSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(null)
  const [validationErrors, setValidationErrors] = useState(null)
  const apiUrl =site.siteMetadata.formSubmissionUrl
 const { executeRecaptcha } = useGoogleReCaptcha()
 const jsonToFormData = (json) => {
  try {
    const data = new FormData()

    for (let k in json) {
      data.append(k, json[k])
    }

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

  return (
    <>
      <section className="newsroom-first-block-sus">
        <div className="container">
      
          
                
                <div className="col-group">
                  <div className="col-dt-7">
                    <div className="img-wrapper">
                      <img  
                        src={props?.data?.image?.localFile?.publicURL}
                        alt={props?.data?.image?.altText || props?.data?.title}
                      />
                    </div>
                  </div>
                  <div className="col-dt-5">
                    <div className="second-col-wrapper">
                      <div className="lifestyle-wrapper">
                        <div
                          className="lifestyle-title h2"
                          dangerouslySetInnerHTML={titleDesign(props?.data?.title)}
                        ></div>
                        <div
                          className="lifestyle-desc"
                          dangerouslySetInnerHTML={createMarkup(
                            (props?.data?.text)
                          )}
                        ></div> 
                        <div className="read-the-story">
                          <div className="learn-more" >
                        {  (props?.data?.file!=null) ? (
                         <a 
                         onClick={handleModalOpenDownload}
                         data-file={props?.data?.title}
                    state={{ parenturl: originalUrl }}
                    className="download-btn"
                  >
                              {string_translation(
                                stringTranslations,
                                "downlaod",
                                locale
                              )}
                              </a>
                        ) : ('')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <ReactModal className="downloadModal"
                    isOpen={isOpenDownload}
                      onRequestClose={handleModalClose}
                      
                    >
                    
<div className="get-in-touch">
<button className="modal-video-close-btn"  onClick={handleModalClose}></button>
   <div className="form-wrapper">                      <div className="get-in-touch-form-wrapper">
                      <div>
                      <form onSubmit={handleFormSubmit}>
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}



      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Invest"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Invest"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  required="required"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "lname", locale)}
                  // value={lastname}
                  required="required"
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "email", locale)}
                  // value={email}
                  required="required"
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "phone", locale)}
                  // value={number}
                  required="required"
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div>
            </div>
          </div>

          <div className="label-wrapper last-label-wrapper">
            <div className="label">
              
              <select placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                required="required"
                onChange={(e) => handleFieldChange("msg", e)}  >
                   <option value="" disabled selected>{string_translation(stringTranslations, "msg", locale)}</option>
                <option>{string_translation(stringTranslations, "selectowner", locale)}</option>
<option>{string_translation(stringTranslations, "selectengoff", locale)}</option>
<option>{string_translation(stringTranslations, "selectpbuyer", locale)}</option>
<option>{string_translation(stringTranslations, "selectagent", locale)}</option>
<option>{string_translation(stringTranslations, "selectother", locale)}</option>
                </select>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
          <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
        
        </div>
          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    
    </div> </div></div></div>
                    </ReactModal>
            <div className="col-group">
              <div className="col-dt-7"></div>
              <div className="col-dt-5">
                   &nbsp;
              </div>
            </div>
         
        </div>
      </section>
    </>
  )
}
