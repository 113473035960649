import React, { useState, useMemo, useEffect, useContext } from "react"
import "./updates.scss"
import "./pagination.scss"
import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql,navigate } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import ReactModal from 'react-modal'
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'



export default function LatestSustainabilityReports(props) {
  
  const [todos, setTodos] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [filterCompleted, setFilterCompleted] = useState("")
  const [filterCompletedCat, setFilterCompletedCat] = useState("")
  const [filterCompletedTag, setFilterCompletedTag] = useState("")

  const [active, setactive] = useState(false)
  const [hasactivefilter, sethasactivefilter] = useState(null)

  const {
    locale,
    originalUrl
  } = useContext(TranslateUrlsContext)

  const { stringTranslations,site } = useStaticQuery(graphql`
    query {
      stringTranslations {
        latest_ar:translateString(language: AR, string: "LATEST UPDATES")
        latest_en:translateString(language: EN, string: "LATEST UPDATES")
        search_ar:translateString(language: AR, string: "SEARCH")
        search_en:translateString(language: EN, string: "SEARCH")
        advanced_ar:translateString(language: AR, string: "ADVANCED")
        advanced_en:translateString(language: EN, string: "ADVANCED")
        placeholder_ar:translateString(language: AR, string: "NEWSROOM SEARCH PLACEHOLDER")
        placeholder_en:translateString(language: EN, string: "NEWSROOM SEARCH PLACEHOLDER")
  
        report_ar:translateString(language: AR, string: "تقرير")
        report_en:translateString(language: EN, string: "Report")

        tag_ar:translateString(language: AR, string: "TAG")
        tag_en:translateString(language: EN, string: "TAG")
        category_ar:translateString(language: AR, string: "CATEGORY")
        category_en:translateString(language: EN, string: "CATEGORY")
        duration_ar:translateString(language: AR, string: "DURATION")
        duration_en:translateString(language: EN, string: "DURATION")
        cancel_ar:translateString(language: AR, string: "CANCEL")
        cancel_en:translateString(language: EN, string: "CANCEL")

        all_en:translateString(language: EN, string: "ALL")
        all_ar:translateString(language: AR, string: "ALL")
        article_en:translateString(language: EN, string: "ARTICLE")
        article_ar:translateString(language: AR, string: "ARTICLE")
        pressrelease_en:translateString(language: EN, string: "PRESSRELEASE")
        pressrelease_ar:translateString(language: AR, string: "PRESSRELEASE")

        global_en:translateString(language: EN, string: "GLOBAL")
        global_ar:translateString(language: AR, string: "GLOBAL")
        tech_en:translateString(language: EN, string: "TECH")
        tech_ar:translateString(language: AR, string: "TECH")
        sustainability_en:translateString(language: EN, string: "SUSTAINABILITY")
        sustainability_ar:translateString(language: AR, string: "SUSTAINABILITY")
        art_en:translateString(language: EN, string: "ART")
        art_ar:translateString(language: AR, string: "ART")

        newest_en:translateString(language: EN, string: "NEWEST")
        newest_ar:translateString(language: AR, string: "NEWEST")
        oldest_en:translateString(language: EN, string: "OLDEST")
        oldest_ar:translateString(language: AR, string: "OLDEST")

        highlight_en:translateString(language: EN, string: "NO RESULT HIGHLIGHT")
        highlight_ar:translateString(language: AR, string: "NO RESULT HIGHLIGHT")
        text1_en:translateString(language: EN, string: "NO RESULT TEXT 1")
        text1_ar:translateString(language: AR, string: "NO RESULT TEXT 1")
        text2_en:translateString(language: EN, string: "NO RESULT TEXT 2")
        text2_ar:translateString(language: AR, string: "NO RESULT TEXT 2")

              fname_ar:translateString(language: AR, string: "FIRST NAME")
      fname_en:translateString(language: EN, string: "FIRST NAME")
      lname_ar:translateString(language: AR, string: "LAST NAME")
      lname_en:translateString(language: EN, string: "LAST NAME")
      email_ar:translateString(language: AR, string: "EMAIL")
      email_en:translateString(language: EN, string: "EMAIL")
      phone_ar:translateString(language: AR, string: "PHONE")
      phone_en:translateString(language: EN, string: "PHONE")
      msg_ar:translateString(language: AR, string: "صفة المستفيد")
      msg_en:translateString(language: EN, string: "ROLE TYPE")
      submit_ar:translateString(language: AR, string: "SUBMIT")
      submit_en:translateString(language: EN, string: "SUBMIT")


      selectowner_ar:translateString(language: AR, string: "مالك")
      selectowner_en:translateString(language: EN, string: "Owner")
      selectengoff_ar:translateString(language: AR, string: "مكتب هندسي / معماري")
      selectengoff_en:translateString(language: EN, string: "Engineering Office / Architect")
      selectpbuyer_ar:translateString(language: AR, string: "مشتري محتمل")
      selectpbuyer_en:translateString(language: EN, string: "Potential Buyer")
      selectagent_ar:translateString(language: AR, string: "وكالة / وكيل")
      selectagent_en:translateString(language: EN, string: "Agency / Agent ")
      selectother_ar:translateString(language: AR, string: "أخرى")
      selectother_en:translateString(language: EN, string: "Other")
      loading_ar:translateString(language: AR, string: "LOADING")
      loading_en:translateString(language: EN, string: "LOADING")
      ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
      ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
      ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
      ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
      }
    site {
      siteMetadata {
        formSubmissionUrl
      }
    }
    }
  `)
  const [isOpen, setOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalTodos, setTotalTodos] = useState(0)
  const todosPerPage = 6
  const career = props.data
  const tags = null
  const category = null
  let newtodos = []
  let tagsList = []
  let categoryList = []

  const parseDate = (datestring) => {
    let datebits = datestring.split("-");
    let newDate = new Date( datebits[2], datebits[1] - 1, datebits[0]);
    return (newDate.getTime()/1000)
  }

  career.map((item, index) => {
   
      return newtodos.push(item)
    
  })

  tags?.map((item, index) => {
    if (item.node.language.locale.indexOf(locale) !== -1) {
      return tagsList.push(item)
    }
  })

  category?.map((item, index) => {
    if (item.node.language.locale.indexOf(locale) !== -1) {
      return categoryList.push(item)
    }
  })

  useEffect(() => {
    setTodos(newtodos)
  }, [])

  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalTodos / todosPerPage); i++) {
    pageNumbers.push(i)
  }

  const todosData = useMemo(() => {
    let computedTodos = todos

    if (searchTerm) {
      computedTodos = computedTodos.filter(todo =>
        todo?.node?.title?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    if (filterCompletedTag != "" && filterCompletedTag != null && filterCompletedTag != undefined) {
      computedTodos = computedTodos.filter(
        todo => todo.node.tags.nodes[0]?.id === filterCompletedTag
      )
    }

    if (filterCompletedCat != "" && filterCompletedCat != null && filterCompletedCat != undefined) {
      computedTodos = computedTodos.filter(
        todo => todo.node.categories?.nodes[1]?.name === filterCompletedCat
      )
    }
    
    if (filterCompleted === "newest") {
      computedTodos = [...computedTodos].sort((a, b) =>
      parseDate(b.node.date) - parseDate(a.node.date)
      )
    }
    if (filterCompleted === "oldest") {
      computedTodos = [...computedTodos].sort((a, b) =>
      parseDate(a.node.date) - parseDate(b.node.date)
      )
    }

    setTotalTodos(computedTodos.length)

    //Current Page slice
    return computedTodos.slice(
      (currentPage - 1) * todosPerPage,
      (currentPage - 1) * todosPerPage + todosPerPage
    )
  }, [todos, currentPage, searchTerm, filterCompleted,filterCompletedCat,filterCompletedTag])

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  const onNext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1)
    }
  }
  const onPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const resetFilter = () => {
    setSearchTerm("")
    setFilterCompleted("")
    setFilterCompletedCat("")
    setFilterCompletedTag("")
    setCurrentPage(1)
  }
  let textInput = React.createRef()

  const toggleActiveBtn = (e) => {
    if (hasactivefilter !== e?.currentTarget) {
      const btnsTip = document.querySelectorAll(".anchor")
      btnsTip.forEach(btnTip => {
        btnTip.classList.remove("selected")
      })
      e.currentTarget.classList.add("selected")
      sethasactivefilter(e.currentTarget)
    }
    else{
      const filterTarget = e?.currentTarget
      sethasactivefilter(filterTarget)
      filterTarget.classList.toggle("selected")
      !filterTarget.classList.contains("selected") && resetFilter()
    }
    
  }
  const handleKeyDown = event => {
    if (event.key === "Enter" && textInput.current.value !== "") {
      setSearchTerm(textInput.current.value)
      setCurrentPage(1)
    }
  }
  const handleEmpty = () => {
    if (textInput.current.value == "") {
      resetFilter()
    }
  }
  const handleclick = () => {
    setSearchTerm(textInput.current.value)
    setCurrentPage(1)
  }
  let pagination = []
  if ( typeof document !== 'undefined' ) {
    pagination = document.querySelectorAll(".page-item")
  }
  let activepagination = null
  pagination.forEach(btnTip => {
    btnTip.addEventListener("click", e => {
      e.currentTarget.classList.add("active")
      if (activepagination !== null && activepagination !== e.currentTarget) {
        activepagination.classList.remove("active")
      }
      activepagination = e.currentTarget
    })
  })
  let btnsTip = []
  if ( typeof document !== 'undefined' ) {
    btnsTip = document.querySelectorAll(".anchor")
  }
  // let activeBtn = null
  // btnsTip.forEach(btnTip => {
  //   btnTip.addEventListener("click", e => {
  //     if (
  //       e.currentTarget.classList.contains("selected") &&
  //       activeBtn === e.currentTarget
  //     ) {
  //       e.currentTarget.classList.remove("selected")
  //     } else {
  //       e.currentTarget.classList.add("selected")

  //       if (activeBtn !== null && activeBtn !== e.currentTarget) {
  //         activeBtn.classList.remove("selected")
  //       }
  //     }

  //     activeBtn = e.currentTarget
  //   })
  // })
  let pag = newtodos.length >= 6 ? true : false

  const handleModalOpen = event => {
   
    setFormState({
      ...formState,
      ['file']: event.currentTarget.dataset.file
    })
    setOpen(true)
  }
 

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
  const [formState, setFormState] = useState({})
  const handleFormSubmit = (e) => {
    formSubmitHandler(e, formState)
  } 

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }
  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <p>{errors.errors}</p>
         
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  const formSubmitHandler = async (event, payload) => {
    event.preventDefault()
   

   
    const result = await executeRecaptcha('contact')
    var fieldname = "g-recaptcha-response";
    payload[fieldname] = result;
    payload['lang'] = locale;
    setLoading(true)
    setError(null)
    setValidationErrors(null)

    fetch('/reportdownload/report', {
      method: "POST",
      body: jsonToFormData(payload),
    })
    .then((resp) => resp.json())
      .then((resp) => resp)  .then((resp) => {
        if (resp.status !== "success") {
            setValidationErrors(resp.message)
            throw resp.message
        }else{   
        navigate("/"+locale+'/success-download?email='+payload.email)
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const [isSent, setSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(null)
  const [validationErrors, setValidationErrors] = useState(null)
  const apiUrl =site.siteMetadata.formSubmissionUrl
 const { executeRecaptcha } = useGoogleReCaptcha()
 const jsonToFormData = (json) => {
  try {
    const data = new FormData()

    for (let k in json) {
      data.append(k, json[k])
    }

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}
  return (
    <section className="last-updates-sus">
     
      <div className="container">
      <hr className="hrr"/>
       
      
        {todosData.length > 0 ? (
          <div className="col-group last-updates-posts">
            {todosData.map((item, index) => {
              return (
                <div className="col-dt-4 element" key={"n" + index}>
                  <div className="spotlight-title h2">{item?.title}</div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {string_translation(stringTranslations, "report", locale)}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.date}</div>
                    </div>
                    <div className="date-wrapper">
                      <div className="spotlight-title h2">&nbsp;</div>
                      <div className="date date-orange"> <a onClick={handleModalOpen} data-file={item?.title}  > <img  width="25" height="25" src="/images/last-updates/download.png" /></a></div>
                    </div>
                    <div className="spotlight-img">
                      <img
                        src={
                          item?.image?.localFile
                            ?.publicURL
                        }
                        alt={item?.image?.altText || item?.title.substring(0, 147).replace(/<[^>]*>?/gm, '')}
                      />
                    </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="no-result-div">
            <div className="result-wrapper">
              <div className="highlight">{string_translation(stringTranslations, "highlight", locale)}</div>
              <div className="text">
              {string_translation(stringTranslations, "text1", locale)}
              </div>
              <div className="text">
              {string_translation(stringTranslations, "text2", locale)}
              </div>
            </div>
          </div>
        )}

        {pag ? (
          <div className="grid-pagination">
            <ul className="custom-pagination">
              <li>
                <a onClick={onPrevious}>
                  <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                      fill="#3D3935"
                      stroke="#3D3935"
                      stroke-width="0.5"
                    ></path>
                  </svg>
                </a>
              </li>
              {pageNumbers.map(number => (
                <li
                  key={"t" + number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    className="pagination "
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li>
                <a onClick={onNext}>
                  <svg
                    width="6"
                    height="9"
                    viewBox="0 0 6 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.06495 4.98826C5.16042 4.89291 5.21411 4.76355 5.21423 4.62862V4.37076C5.21267 4.23612 5.15919 4.10729 5.06495 4.01112L1.57709 0.530048C1.51338 0.465817 1.42666 0.429688 1.3362 0.429688C1.24573 0.429688 1.15901 0.465817 1.0953 0.530048L0.613519 1.01183C0.54969 1.07437 0.51372 1.15997 0.51372 1.24933C0.51372 1.33869 0.54969 1.42429 0.613519 1.48683L3.63316 4.49969L0.613519 7.51255C0.549288 7.57625 0.513159 7.66297 0.513159 7.75344C0.513159 7.84391 0.549288 7.93063 0.613519 7.99433L1.0953 8.46933C1.15901 8.53356 1.24573 8.56969 1.3362 8.56969C1.42666 8.56969 1.51338 8.53356 1.57709 8.46933L5.06495 4.98826Z"
                      fill="#3D3935"
                      stroke="#3D3935"
                      stroke-width="0.5"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div></div>
        )}

<ReactModal className="downloadModal"
                      isOpen={isOpen}
                      onRequestClose={handleModalClose}
                      
                    >
                    
<div className="get-in-touch">
<button className="modal-video-close-btn"  onClick={handleModalClose}></button>
   <div className="form-wrapper">                      <div className="get-in-touch-form-wrapper">
                      <div>
                      <form onSubmit={handleFormSubmit}>
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}



      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Invest"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Invest"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  required="required"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "lname", locale)}
                  // value={lastname}
                  required="required"
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "email", locale)}
                  // value={email}
                  required="required"
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "phone", locale)}
                  // value={number}
                  required="required"
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div>
            </div>
          </div>

          <div className="label-wrapper last-label-wrapper">
            <div className="label">
              
              <select placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                required="required"
                onChange={(e) => handleFieldChange("msg", e)}  >
                   <option value="" disabled selected>{string_translation(stringTranslations, "msg", locale)}</option>
                <option>{string_translation(stringTranslations, "selectowner", locale)}</option>
<option>{string_translation(stringTranslations, "selectengoff", locale)}</option>
<option>{string_translation(stringTranslations, "selectpbuyer", locale)}</option>
<option>{string_translation(stringTranslations, "selectagent", locale)}</option>
<option>{string_translation(stringTranslations, "selectother", locale)}</option>
                </select>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
          <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
        
        </div>
          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    
    </div> </div></div></div>
                    </ReactModal>
      </div>
    </section>
  )
}
