import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import WhoWeAre from '../components/WhoWeAre'
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import LatestSustainabilityReports from '../components/LatestSustainabilityReports'
import SustainabilityReport from '../components/SustainabilityReport'
export default function SustainabilityReportData(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
 console.log(data)
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
        <div className="cic-activity-who-we-are">
          <WhoWeAre
            title={data?.wpPage?.pageSubtitle?.subtitle}
            name={data?.wpPage?.title}
            description={data?.wpPage?.pageDescription?.pageDescription}
          />
        </div>
          <SustainabilityReport data={data?.wpPage?.sustainabilityReport}/>
          
          {(data?.wpPage?.sustainabilityReport?.reports?.length>0)?
          <LatestSustainabilityReports data={data?.wpPage?.sustainabilityReport?.reports}/>:''
          }
   
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query SustainabilityReportData($databaseId:Int) {
 
  
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        altText
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    sustainabilityReport {
      file
      image{
        altText
        localFile {
          publicURL
        }
      }
      title
      text
      reports{
      title
      date
      file
      image{
        altText
        localFile {
          publicURL
        }
      }
      }
    }   
  }
}`);